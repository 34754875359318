<template>
  <div>
    <v-dialog v-model="open" content-class="v-dialog--overlay" width="500">
      <v-form v-model="valid">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $tc('admin.document.title', count) }}</span>
          </v-card-title>

          <v-card-text class="form">
            <span>{{ $tc('admin.document.uploadText', count) }}</span>

            <v-divider />

            <v-file-input
              v-model="files"
              :accept="$props.acceptedDocuments"
              :label="$tc('admin.document.placeholder', count)"
              :multiple="isMultiple"
              :rules="fileSizeRule"
              show-size
            />
          </v-card-text>

          <v-card-actions class="actions">
            <v-btn class="mx-2" color="primary" outlined @click.native="close">
              {{ $t('globals.cancel') }}
            </v-btn>

            <v-btn :disabled="isSaveDisabled" color="primary" @click="save">
              {{ $t('globals.upload') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';
import FORM from '@/helpers/forms/form.helper';

export default {
  name: 'UploadFiles',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    acceptedDocuments: {
      type: String,
      default: FORM.DOCUMENTS,
    },
  },
  data: () => ({
    valid: false,
    files: null,
    fileSizeRule: VALIDATORS.FILE,
  }),
  computed: {
    count() {
      return this.isMultiple ? 2 : 1;
    },
    isSaveDisabled() {
      return (this.files || []).length <= 0 || !this.valid;
    },
    open: {
      get() {
        return this.dialog;
      },
      set() {
        return this.close();
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.files);
      this.files = null;
    },
    close() {
      this.$emit('close');
      this.files = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.form {
  display: flex;
  flex-direction: column;
}
</style>
