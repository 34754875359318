<template>
  <div>
    <v-data-table
      class="a-datatable"
      :style="{ backgroundColor: `background-color: ${backgroundColor}` }"
      v-bind:class="additionalClasses"
      :items="items"
      :loading="loading"
      :header-props="headerProps"
      :headers="headers"
      :search="search"
      :footer-props="footerProps"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :mobile-breakpoint="$vuetify.breakpoint.mobileBreakpoint"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isDesc"
      :hide-default-header="hideHeaders || displayMobileView"
      :disable-sort="isSortDisabled"
      :items-per-page="itemPerPage"
      :show-select="showSelect"
      :show-expand="showExpand"
      :expanded.sync="expandData"
      :hide-default-footer="!showFooter"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>

      <template v-slot:[`expanded-item`]="{ item }">
        <slot :name="`expanded-item`" v-bind:item="item" />
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ props.pageStart }}-{{ props.pageStop }} {{ $t('globals.datatable.of') }}
        {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'BaseList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    headerProps: {
      type: Object,
      default: () => ({}),
    },
    footerProps: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    total: {
      type: Number,
      default: 0,
    },
    hideHeaders: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Number,
      default: 10,
    },
    defaultSortDesc: {
      type: Boolean,
      default: true,
    },
    isSortDisabled: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: '$globals.datatable.noData',
    },
    noResultsText: {
      type: String,
      default: '$globals.datatable.noResult',
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDesc: true,
      sortBy: '',
    };
  },
  computed: {
    additionalClasses() {
      return this.className;
    },
    displayMobileView() {
      return this.isMobile || this.$vuetify.breakpoint.mobile;
    },
    expandData: {
      get() {
        return this.expanded;
      },
      set(newData) {
        this.$emit('expand-change', newData);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/mixins';

::v-deep.a-datatable.img-first.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:first-child {
  padding: 0;
}

::v-deep.a-datatable.v-data-table > .v-data-table__wrapper {
  overflow: inherit;

  & > table {
    border-collapse: separate;
    border-spacing: 0 20px;
    table-layout: fixed;

    & > thead {
      & > tr {
        & > th {
          border: none !important;
          height: 10px;
        }
      }
    }

    & > tbody {
      & > tr {
        @include admin-card;

        margin-bottom: 10px;

        &.v-data-table__empty-wrapper {
          & > td {
            border-radius: 10px !important;
          }
        }

        & > td {
          padding: 10px;

          &:first-child {
            border-radius: 10px 0 0 10px;
            max-height: 100px;
          }
          &:last-child {
            border-left: 1px solid var(--v-gray-base);
            border-radius: 0 10px 10px 0;

            & > * {
              justify-content: center;
            }

            &:hover {
              border-radius: 0 10px 10px 0;
            }
          }

          &.v-data-table__expanded {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            box-shadow: unset;

            &__row {
              background: white !important;
            }

            &__content {
              background: transparent !important;

              td {
                padding: 0 !important;
              }
            }

            td {
              border-bottom-right-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
