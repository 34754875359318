<template>
  <div class="partner-table">
    <div class="partner-table__actions">
      <v-btn
        class="actions__create"
        large
        depressed
        color="primary"
        @click="addPartner()"
        :disabled="!canAdd"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch v-if="searchBar" class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      class="mt-4"
      :backgroundColor="backgroundColor"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :items="partners"
      :search="search"
      :loading="partnersAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ item.type }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="user"
          :has-delete="true"
          :can-edit="canEdit"
          :can-delete="canDelete"
          @edit-item="editPartner(item)"
          @delete-item="openDeletePartnerDialog(item)"
        />
      </template>
    </base-list>

    <dialog-confirmation
      :visible="dialog.delete"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :content="$t('admin.partners.delete.deleteConfirmationDialog.content')"
      @cancel="closeDialog('delete')"
      @confirm="deletePartner"
    />

    <v-dialog v-model="dialog.edit" v-if="dialog.edit" width="800" @click:outside="closeDialog">
      <v-card>
        <v-card-title>
          {{ event.name }} -
          {{ $t(`admin.partners.editModal.title.${modalTitle}`) }}
        </v-card-title>

        <v-card-text>
          <edit-partner-admin
            :eventId="eventId"
            :partnerId="partnerId"
            @closeDialog="closeDialog('edit')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import EditPartnerAdmin from '@/views/admin/partners/edit/EditPartnerAdmin.vue';

import {
  DELETE_PARTNER,
  GET_PARTNERS,
  PARTNER_MODULE,
} from '@/stores/umanize-admin/actions/partners/partners.actions';

export default {
  name: 'PartnerTable',
  components: {
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    EditPartnerAdmin,
    AppSearch,
  },
  props: ['user', 'searchBar', 'canEdit', 'canAdd', 'canDelete', 'backgroundColor'],
  data: () => ({
    currentPartner: null,
    search: '',
    modalTitle: 'edit',
    dialog: {
      edit: false,
      delete: false,
    },
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(PARTNER_MODULE, ['partners', 'partnersAreLoading']),
    headers() {
      return [
        {
          text: this.$t('admin.partners.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('admin.partners.headers.type'),
          value: 'type',
        },
        {
          text: this.$t('admin.partners.headers.priority'),
          value: 'priority',
          align: 'center',
          width: '100px',
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    partnerId() {
      return this.currentPartner?.id;
    },
  },
  methods: {
    ...mapActions(PARTNER_MODULE, [GET_PARTNERS, DELETE_PARTNER]),
    onSearchChange(search) {
      this.search = search;
    },
    loadPartners() {
      this[GET_PARTNERS]({
        eventId: this.eventId,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
    },
    addPartner() {
      this.modalTitle = 'create';
      this.dialog.edit = true;
    },
    editPartner(item) {
      this.modalTitle = 'edit';
      this.currentPartner = { ...item };
      this.dialog.edit = true;
    },
    closeDialog(item) {
      this.currentPartner = null;
      this.dialog[item] = false;
      this.loadPartners();
    },
    openDeletePartnerDialog(item) {
      this.currentPartner = { ...item };
      this.dialog.delete = true;
    },
    async deletePartner() {
      await this[DELETE_PARTNER]({
        partner: this.currentPartner,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.closeDialog('delete');
    },
  },
  mounted() {
    this.loadPartners();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

@include admin-layout;

.partner-table {
  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
