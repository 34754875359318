<template>
  <div class="base-input-multiple">
    <div class="base-input-multiple__label">{{ label }}</div>

    <div v-if="!isObject">
      <v-textarea
        v-for="(line, idx) in form"
        :key="idx"
        v-model="form[idx]"
        dense
        :placeholder="placeholder"
        :no-resize="true"
        :disabled="disabled"
        rows="1"
        class="base-input-multiple__input"
        append-outer-icon="mdi-close"
        @click:append-outer="removeLine(idx)"
        @change="emitChanges"
      />
    </div>

    <div v-else>
      <v-textarea
        v-for="(line, idx) in form"
        :key="idx"
        v-model="form[idx].name"
        dense
        :placeholder="placeholder"
        :no-resize="true"
        :disabled="disabled"
        rows="1"
        class="base-input-multiple__input"
        append-outer-icon="mdi-close"
        @click:append-outer="removeLine(idx)"
        @change="emitChanges"
      />
    </div>

    <v-btn :disabled="disabled" icon color="primary" @click.native="addLine()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseInputMultiple',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    values: {
      type: Array,
      required: false,
      default: () => [],
    },
    hasInternalAdd: {
      type: Boolean,
      required: false,
      default: true,
    },
    isObjectArray: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: this.values || [],
    };
  },
  computed: {
    isObject() {
      return this.isObjectArray;
    },
  },
  methods: {
    emitChanges() {
      this.$emit(
        'on-change',
        this.isObject
          ? this.form.map((item) => ({ ...item, name: item.name.trim() })).filter((a) => !!a.name)
          : this.form.map((line) => line.trim()).filter((a) => !!a),
      );
    },
    addLine() {
      if (!this.hasInternalAdd) {
        this.$emit('add');
      } else {
        this.form.push('');
      }
    },
    removeLine(index) {
      this.form.splice(index, 1);
      this.emitChanges();
    },
  },
  watch: {
    values() {
      this.form = this.values;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/core/variables';

.base-input-multiple {
  &__label {
    width: 100%;

    font-weight: $semi-bold;
  }

  &__input {
    width: 100%;
  }
}
</style>
