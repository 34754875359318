<template>
  <div class="d-flex">
    <slot></slot>
    <v-btn icon @click="editItem" data-test-id="edit-btn">
      <v-icon v-if="canEdit" :title="$t('globals.edit')">mdi-cog-outline</v-icon>
      <v-icon v-else :title="$t('globals.edit')">mdi-eye</v-icon>
    </v-btn>

    <v-btn v-if="!isDisabled && canDelete" icon @click="deleteItem" data-test-id="delete-btn">
      <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
    </v-btn>

    <v-tooltip v-if="isDisabled && canDelete" bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn icon disabled>
            <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <span>{{ disabledTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'AdminTableActions',
  props: {
    user: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasCertificate: {
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editItem() {
      this.$emit('edit-item');
    },
    deleteItem() {
      this.$emit('delete-item');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
</style>
