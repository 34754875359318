<template>
  <v-container class="edit-partner" v-if="!partnerIsLoading">
    <v-form v-model="valid" @submit="save" @submit.prevent :disabled="!canUpdate">
      <v-row>
        <v-col cols="12" sm="6" v-if="isEditing">
          <v-text-field
            disabled
            v-model="partnerForm.id"
            :label="$t('admin.partners.editModal.id')"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>

        <v-col cols="12" sm="6" v-if="isEditing"></v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="partnerForm.name"
            :label="$t('admin.partners.editModal.name')"
            :rules="nameRule"
            required
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            v-model="partnerForm.type"
            :items="getPartnerTypes"
            item-value="id"
            item-text="label"
            :label="$t('admin.partners.editModal.type')"
            :rules="typeRule"
            required
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model.number="partnerForm.priority"
            :label="$t('admin.partners.editModal.priority')"
            type="number"
            :rules="priorityRule"
            required
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>

        <v-col cols="12" sm="6" class="d-flex flex-column">
          <div>{{ $t('admin.partners.editModal.logoMobile') }}</div>
          <v-text-field
            v-model="partnerForm.logoMobile"
            :rules="logosRule"
            v-show="false"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
          <v-img class="img-container my-2" height="140" width="300" :src="partnerForm.logoMobile">
            <p v-if="!partnerForm.logoMobile">
              {{ $t('event.edit.noCover') }}
            </p>
          </v-img>

          <div>
            <v-btn v-if="canUpdate" color="primary" @click="openDialog('logoMobile')">
              {{ $t('globals.upload') }}
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" sm="6" class="d-flex flex-column">
          <div>{{ $t('admin.partners.editModal.logoWeb') }}</div>
          <v-text-field v-model="partnerForm.logoWeb" :rules="logosRule" v-show="false" />

          <v-img class="img-container my-2" height="140" width="300" :src="partnerForm.logoWeb">
            <p v-if="!partnerForm.logoWeb">
              {{ $t('event.edit.noCover') }}
            </p>
          </v-img>

          <div>
            <v-btn v-if="canUpdate" color="primary" @click="openDialog('logoWeb')">
              {{ $t('globals.upload') }}
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-card-actions class="actions">
        <v-btn color="primary" outlined @click="goBackToList" data-test-id="btn-cancel">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="!valid || !canUpdate">
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <upload-pictures
      :dialog="dialog"
      :is-multiple="false"
      :height="pictureDialogHeight"
      :width="pictureDialogWidth"
      @save="uploadLogo"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { Partner } from '@/models/event/partner.model';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  CLEAR_PARTNER,
  GET_PARTNER,
  PARTNER_MODULE,
  SAVE_PARTNER,
} from '@/stores/umanize-admin/actions/partners/partners.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'EditPartnerAdmin',
  components: {
    UploadPictures,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    partnerId: {
      type: String,
    },
  },
  data: () => ({
    dialog: false,
    dialogType: null,
    defaultPartner: new Partner(),
    partnerForm: new Partner(),
    partnerType: 'event',
    partnerTypes: {
      event: [
        {
          id: 'other',
          label: 'Only on Lobby and Schedule',
        },
        {
          id: 'regular',
          label: 'Regular',
        },
        {
          id: 'platinium',
          label: 'Platinium',
        },
      ],
      conference: [
        {
          id: 'principal',
          label: 'Principal (under the event logo)',
        },
        {
          id: 'regular',
          label: 'Bottom of the page',
        },
      ],
    },
    nameRule: VALIDATORS.PARTNERS.NAME,
    typeRule: VALIDATORS.PARTNERS.TYPE,
    priorityRule: VALIDATORS.PARTNERS.PRIORITY,
    logosRule: VALIDATORS.PARTNERS.LOGOS,
    valid: false,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    pictureDialogConfig: {
      logoMobile: {
        height: 200,
        width: 250,
      },
      logoWeb: {
        height: 0,
        width: 0,
      },
    },
  }),
  computed: {
    ...mapGetters(PARTNER_MODULE, ['partner', 'partnerIsLoading']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    isEditing() {
      return !!this?.partner?.id;
    },
    getPartnerTypes() {
      return this.partnerTypes[this.$route.meta.partnerType || 'event'];
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(PARTNER_MODULE, [GET_PARTNER, CLEAR_PARTNER, SAVE_PARTNER]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initFormFromPartner() {
      this.partnerForm = {
        ...this.defaultPartner,
        ...cloneDeep(this.partner),
        eventId: this.eventId,
      };
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    async save() {
      await this[SAVE_PARTNER]({
        partner: this.partnerForm,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.goBackToList();
    },
    openDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.dialog = true;
    },
    closeDialog() {
      this.dialogType = null;
      this.dialog = false;
    },
    async uploadLogo(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.partnerForm[this.dialogType] = this.file.url;
      this.closeDialog();
    },
  },
  async mounted() {
    if (this.partnerId) {
      await this[GET_PARTNER]({
        eventId: this.eventId,
        partnerId: this.partnerId,
        conferenceId: this.$route.params.conferenceId,
        discussionGroupId: this.$route.params.discussionGroupId,
      });
      this.initFormFromPartner();
    } else {
      this.partnerForm = {
        ...new Partner(),
        eventId: this.eventId,
      };
    }
  },
  async beforeDestroy() {
    await this[CLEAR_PARTNER]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
@import '@styles/core/mixins';

@include admin-layout();

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}
</style>
